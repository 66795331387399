import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope,  faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Obfuscator from "./Obfuscator";

function Footer() {

    const mail = 'contact@agantar.com';
    const phone = '+33658008765';

    return (
        <footer className="bg-agantar-blue-dark dark:bg-black/30 text-white/90 w-full">  
        
            <div className="page py-4 xs:py-6 xs:flex xs:justify-center">
                
                <img src="images/logo.svg" className="w-32 xs:mr-12 mx-auto xs:m-0 mb-6" alt="Agantar"/>

                <div className="flex justify-center xs:justify-between items-center md:w-full">                    
                    <div className="text-xs text-white/80 hidden md:block">
                        Agantar ©2024 - SIREN 490830775<br/>
                        5 rue du Vinarium 11170 VILLESÈQUELANDE - 
                        <a href="/sitemap.xml" className="ml-1 text-link">Sitemap</a>
                    </div>
                    
                    <div className="col flex gap-x-4 items-center">

                        <a href="https://instagram.com/agantar_officiel" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="text-2xl text-link"/>
                        </a>
                        <a href="https://linkedin.com/company/agantar" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} className="text-2xl text-link"/>
                        </a>
                    </div>

                </div>

            </div>
                <div className="text-xs text-white/80 pb-6 px-6 text-center md:hidden">
                    Agantar ©2024 - SIREN 490830775 - 5 rue du Vinarium 11170 VILLESÈQUELANDE - 
                    <a href="/sitemap.xml" className="ml-1 text-link">Sitemap</a>
                </div>
        </footer>
    );
}

export default Footer;