import './styles/App.css';
import { Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomeView from './views/HomeView';
import Footer from './components/Footer';
import BlogView from './views/BlogView';
import ServicesView from './views/ServicesView';
import AboutView from './views/AboutView';
import SolutionsView from './views/SolutionsView';
import ContactView from './views/ContactView';
import { useFlashMessage } from './context/FlashMessageContext';
import FlashMessage from "./components/FlashMessage";
// import TailwindCss from './components/helpers/TailwindCss';

function App() {

  const { flashMessage, flashType } = useFlashMessage();

  return (
    <div className="App">
      {flashMessage && <FlashMessage message={flashMessage} type={flashType} />}

      {/* <TailwindCss /> */}
      <Navigation />
      <main  className='page'>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/blog" element={<BlogView />} />
          <Route path="/services" element={<ServicesView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/solutions" element={<SolutionsView />} />
          <Route path="/contact" element={<ContactView />} />
        </Routes>
        {/* <aside className="w-24 bg-green-500"></aside> */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
