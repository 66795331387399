import React from "react";

const SolutionsView = () => {
    return (
        <div>
            <h1>Solutions</h1>
        </div>
    );
}

export default SolutionsView;