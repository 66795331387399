import React , {useEffect}from "react";
import Hero from "../components/homeview/Hero";
// import Blog from "../components/homeview/Blog";
// import Services from "../components/homeview/Services";
// import About from "../components/homeview/About";

function HomeView() {
    useEffect(() => {
        document.title = "Agantar - Créateur de Solutions numériques"
      }, [])

    return (
        <div id="homeView">
            <Hero />
            {/* <Services className="mb-12"/>
            <About className="mb-12"/>
            <Blog className="mb-12"/> */}
        </div>
    );
}

export default HomeView;