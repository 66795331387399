import React, { useEffect, useState } from 'react';
import '../styles/FlashMessage.css'; // Optional: for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface FlashMessageProps {
  message: string;
  type: string;
}

const FlashMessage: React.FC<FlashMessageProps> = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000); // Auto dismiss after 3 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    // Close the flash message
    setIsVisible(false);
  };

  return (
      <div className={`flash-container  ${isVisible ? 'slide-down' : 'slide-up'}`}>
        <div className={`flash-message ${type}`}>
          <span className="message">{message}</span>        
          <button onClick={handleClose}><FontAwesomeIcon icon={faXmark} /></button>
          </div>
      </div>
  );
};

export default FlashMessage;