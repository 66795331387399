import React from "react";

const BlogView = () => {
    return (
        <div>
            <h1>Blog</h1>
        </div>
    );
}

export default BlogView;