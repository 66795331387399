import React from "react";
import '../../styles/Hero.css';
import ImgSize from "../helpers/ImgSize";

interface HeroProps {
    className?: string;
}

const Hero: React.FC<HeroProps> = ({ className }) => {
    return (
        <div id="hero" className={className}>

            <div className="hero-1 flex flex-col gap-6 items-stretch md:flex-row mb-12">
                <div className="text">
                    <div className="title-group">
                        <h3 className="title title-1">créateur de</h3>
                        <h3 className="title title-2">Solutions</h3>
                        <h3 className="title title-3">Numériques</h3>
                    </div>

                    <div className="text-justify">
                            <p className="py-2">Chez Agantar, nous transformons vos idées en solutions numériques innovantes. Que vous ayez besoin d’un site web, d’une boutique en ligne, ou d’une application sur mesure, nous avons l’expertise pour réaliser vos projets.</p>
                            <p className="py-2">Nous vous accompagnons dans la digitalisation de vos processus métiers pour une meilleure efficacité opérationnelle et une expérience client améliorée.</p>
                    </div>
                </div>

                <div className="image">
                    <img 
                        src="images/sizes/320px.png" 
                        alt="sizes"
                        className="mx-auto w-full !max-w-[600px]"
                        srcSet="images/hero1-320px.png 320w, 
                            images/hero1-500px.png 500w,
                            images/hero1-600px.png 600w"
                        sizes="(max-width: 320px) 320px,
                            (max-width: 480px) 500px,
                            (max-width: 640px) 600px,
                            (min-width: 1120px) 600px,
                            (min-width: 768px) 500px"
                    />
                </div>
            </div>


            <div className="hero-2 flex flex-col gap-6 items-stretch md:flex-row mb-12 ">
            
                <div className="image order-1 md:order-first object-cover">
                    <img 
                        src="images/hero2-600px.jpg" 
                        alt="sizes"
                        className="object-cover w-full h-full max-w-[600px] rounded-xl overflow-hidden"
                        srcSet="
                            images/hero2-320px.jpg 320w, 
                            images/hero2-500px.jpg 480w,
                            images/hero2-600px.jpg 640w
                            "
                        sizes="(max-width: 320px) 320px,
                            (max-width: 480px) 480px,
                            (max-width: 640px) 640px,
                            (min-width: 767px) 640px"
                    />
                </div>

                <div className="text">
                    <div className="title-group pb-8">
                        <h4 className="title title-1">Un accompagnement</h4>
                        <h4 className="title title-2">Personnalisé</h4>
                    </div>

                    <div className="text-justify">
                        <p className="py-2">Agantar offre aussi un accompagnement personnalisé pour vous aider à y voir plus clair lors des échanges avec vos prestataires informatiques. Nous veillons à ce que vous compreniez chaque étape, évitant ainsi les malentendus et optimisant la collaboration.</p>
                        <p className="py-2">Faites nous confiance pour booster votre efficacité et assurer la pérennité de vos relations professionnelles.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;