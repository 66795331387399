import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBars, faHome } from "@fortawesome/free-solid-svg-icons";
import '../styles/Navigation.css';

const Navigation = () => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const btnStyle = "font-roboto-slab text-gray-600 dark:text-slate-200";

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const hideMenu = () => {
        setMenuOpen(false);
    }

    return (            
            <nav className="page">
                <div className="flex items-center justify-between">


                    {/* LOGO */}
                    <div className="logo py-1">
                        <Link to="/">
                        <div className="bg-agantar-blue dark:bg-transparent p-4 rounded-lg">
                            <img src="images/logo.svg" alt="" className="w-32"/>
                        </div>
                        </Link>
                    </div>


                    {/* CENTER MENU GROUP */}
                    <div className=" hidden md:block nav-container flex gap-x-8 py-3 px-5 rounded-xl items-center justify-center bg-gray-100 dark:bg-black/20">
                        <Link to="/" className={btnStyle}>Accueil</Link>
                        {/* <Link to="/solutions" className={btnStyle}>Solutions</Link>             
                        <Link to="/services" className={`${btnStyle} underline !text-white`}>Services</Link>            
                        <Link to="/about" className={btnStyle}>À propos</Link>
                        <Link to="/blog">Blog</Link> */}
                    </div>


                    {/* RIGHT MENU GROUP */}
                    <div className="right-menu-group flex gap-x-4 items-center">
                        <Link to="/contact" className="text-white text-sm bg-agantar-blue px-3 py-2 rounded-xl flex gap-x-2 items-center hover:bg-agantar-blue-dark transition">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span className="hidden xs:inline">Contact</span>
                        </Link>
                        <div className="md:hidden">
                            {/* <button className={`burger text-lg transition-transform ${ menuOpen && 'rotate'}`} onClick={handleResponsiveMenu}><FontAwesomeIcon icon={faBars} /></button> */}
                            <button
                                className="p-4 focus:outline-none md:hidden dark:text-slate-200 text-black"
                                onClick={toggleMenu}

                            >
                                <svg
                                    className="w-6 h-6 dark:text-white text-agantar-blue-dark"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>

                {/* RESPONSIVE MENU GROUP */}
                <div className={`md:hidden fixed transform top-16 right-0 ${ menuOpen ? 'translate-x-0' : 'translate-x-64' } transition-transform duration-300 ease-in-out text-center`}>
                        <div className="flex flex-col">
                            <Link to="/" onClick={hideMenu} className="responsive-menu-item">
                                <FontAwesomeIcon icon={faHome} className="pr-2"/>
                                Accueil
                            </Link>
                            <Link to="/contact" onClick={hideMenu} className="responsive-menu-item">
                                <FontAwesomeIcon icon={faEnvelope} className="pr-2"/>
                                Contact
                            </Link>
                        </div>                
                </div>

            </nav>
    );
}

export default Navigation;