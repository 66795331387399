// FlashMessageContext.js
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface FlashMessageContextProps {
  flashMessage: string;
  flashType: string;
  addFlashMessage: (message: string, type: string) => void;
}

interface FlashMessageProviderProps {
  children: ReactNode;
}

const FlashMessageContext = createContext<FlashMessageContextProps | undefined>(undefined);
export const useFlashMessage = () => {
  const context = useContext(FlashMessageContext);
  if (!context) {
    throw new Error('useFlashMessage must be used within a FlashMessageProvider');
  }
  return context;
};

export const FlashMessageProvider: React.FC<FlashMessageProviderProps>  = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState('');
  const [flashType, setFlashType] = useState('success');

  const addFlashMessage = (message: string, type:string = 'success') => {
    setFlashMessage(message);
    setFlashType(type);
    setTimeout(() => {
      setFlashMessage('');
    }, 3500);
  };

  return (
    <FlashMessageContext.Provider value={{ flashMessage, flashType, addFlashMessage }}>
      {children}
    </FlashMessageContext.Provider>
  );
};